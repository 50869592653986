import React, { useContext } from 'react'
import {
  ButtonGroup,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import {
  CalendarToday,
  Help,
  TrackChanges,
  WarningRounded
} from '@mui/icons-material'
import GenericPage from '@templates/Generic/Page'
import { Card, WithCaution } from '@components'
import { AnimatePresence, motion } from 'framer-motion'
import QueueContext from '@contexts/QueueContext'
import { deleteTasks } from '@utils/api'
import useApi from '@utils/hooks/useApi'

const ScanPage = () => {
  const { items, loading } = useContext(QueueContext)

  const purgeApi = useApi<any>({
    apiMethod: deleteTasks,
    requestOnMount: false
  })

  const handlePurge = () => {
    purgeApi.makeRequest().then(() => {
      // TODO: add some error handling in here
    })
  }

  return (
    <GenericPage
      title="Scan Queue"
      breadcrumbs={[
        {
          title: 'Scanning',
          to: '/scan/'
        },
        {
          title: 'Scanning Queue'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Scan Page Actions"
        >
          <Button endIcon={<TrackChanges />} to="/scan/">
            New Scan
          </Button>
          <Button endIcon={<CalendarToday />} to="/scheduler/">
            Schedule Scan
          </Button>
          <WithCaution message="This will remove the server's record of all items in the task queue, but will not cancel any scans in progress. Only purge the task queue if tasks are stuck and blocking new scans of hosts.">
            <Button endIcon={<WarningRounded />} onClick={handlePurge} to="/">
              Purge Queue
            </Button>
          </WithCaution>
        </ButtonGroup>
      }
    >
      <AnimatePresence>
        {items.length > 0 &&
          items.map((item) => (
            <motion.div
              key={`${item.name}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Card>
                <ListItem component="div">
                  <ListItemIcon>
                    <TrackChanges fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Name: ${item.name}`}
                    secondary={`Hosts in Queue: ${item.total}`}
                  />
                </ListItem>
              </Card>
            </motion.div>
          ))}
        {items.length < 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Card>
              <ListItem component="div">
                <ListItemIcon>
                  <Help fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  primary={'Currently no items in queue'}
                  secondary={
                    loading ? <em>Checking...</em> : `I'll check again shortly`
                  }
                />
              </ListItem>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </GenericPage>
  )
}

export default ScanPage
